import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import StripeCheckoutForm from './StripeCheckoutForm';
import './Stripe.css';
import Logo from './Logo';

// Make sure to call loadStripe outside the render function to avoid recreating
// the Stripe object on every render. Note: this needs to be the publishable key!
const stripePromise = loadStripe('pk_live_HCcStffYZyh54d8WT87Ldrs600NSPmCNUv');
const wedfDonateApiDomain = 'https://donate-api.worldethicaldata.org';
const wedfApiKey = "Q6trH3EV2M8e8hrb5fF2sQerVKofRTwMbKbByqFK";

export default function Stripe({ amount, currency = 'eur', typeDonation = 'onetime', checkoutUrl }) {
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        if (typeDonation === 'onetime') {
            // Create a PaymentIntent as soon as the page loads
            fetch(wedfDonateApiDomain + '/create-stripe-payment-intent', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + wedfApiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ amount: amount, currency: currency }),
            })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));
        }
    }, [amount, currency, typeDonation]);

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#1ad3af'
        }
    };
    const options = {
        clientSecret,
        appearance,
    };

    useEffect(() => {
        if (checkoutUrl !== "") {
            window.top.location = checkoutUrl;
            return;
        }
    }, [checkoutUrl]);

    return (
        <div className='m-5 rounded border border-wedf-grey-900 max-w-xl stripe-component mx-auto bg-white'>
            <div>
                <Logo />
            </div>

            <div className="p-5">
                <div className="donation-amount text-center">
                    <div className='text-sm'>Donation amount:</div>
                    <div className='text-2xl mb-5 font-semibold'>&euro; {(amount/100).toFixed(2)}</div>
                </div>

                {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <StripeCheckoutForm />
                    </Elements>
                )}
            </div>
        </div>
    );
}