import Button from './Button';

export default function DonateGroup({ currencySymbol, setAmount, activeButton, setActiveButton, setEnteredCustomAmount, setSelectedAmount, amounts }) {
    return (
        <div className="grid sm:grid-cols-3 grid-cols-2 mb-5">
            {amounts.map((amount, idx) => {
                if (activeButton[idx]) {
                    return <Button key={amount} label={`${currencySymbol} ${amount/100}`} value={amount} inverse />
                } else {
                    return <Button key={amount} label={`${currencySymbol} ${amount/100}`} value={amount} onClick={() => {
                        setSelectedAmount(true);
                        setEnteredCustomAmount(false);
                        setAmount(amount);
                        let activeButtons = activeButton;
                        for (let i = 0; i < activeButtons.length; i++) {
                            activeButtons[i] = false;
                        }
                        activeButtons[idx] = true;
                        setActiveButton(activeButtons);
                    }} />
                }
            })}
        </div>
    );
}