import { useEffect } from 'react';

export default function CustomAmount({ currencySymbol, setAmount, setActiveButton, enteredCustomAmount, setEnteredCustomAmount, setSelectedAmount, isSponsorship = false }) {
    useEffect(() => {
        if (!enteredCustomAmount) {
            // We empty the custom input element if we haven't entered a custom amount (in other words, we clicked one of the buttons).
            const customAmountInputElement = document.querySelector('input#customAmount');
            customAmountInputElement.value = "";
        }
    }, [enteredCustomAmount]);

    return (
        <div className="mx-5">
            {!isSponsorship && 
                <p className="mb-5 text-wedf-grey-200">Or enter a custom donation amount:</p>
            }
            <input id="customAmount" maxlength="10" className="rounded box-border w-full p-5 bg-wedf-grey-950 focus:outline-none focus:ring-2 focus:ring-wedf-green-500 text-center text-2xl font-semibold text-wedf-grey-200" type="text" placeholder={`${currencySymbol} 0`} onKeyUp={(event) => {
                setEnteredCustomAmount(true);
                setActiveButton(new Array(6).fill(false));  // deselect buttons if we're entering a custom amount
                
                let amount = event.target.value;
                if (amount === "" || /[^0-9,.]/g.test(amount)) {
                    setSelectedAmount(false);
                } else {
                    setSelectedAmount(true);
                }
                if (amount.includes('.') || amount.includes(',')) {
                    amount = parseInt(amount.replace(/\D/g, ''));
                } else {
                    amount = parseInt(amount) * 100;
                }
                setAmount(amount);
            }}/>
        </div>
    );
}