import React, { useState, useEffect } from 'react';
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';

export default function StripeCheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();

    // eslint-disable-next-line
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
        );
        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage('Payment succeeded!');
                    break;
                case 'processing':
                    setMessage('Your payment is processing.');
                    break;
                case 'requires_payment_method':
                    setMessage('Your payment was not successful, please try again.');
                    break;
                default:
                    setMessage('Something went wrong.');
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't loaded yet. Make sure to disable form submission
            // until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'https://worldethicaldataforum.org/donate-thank-you',
            },
        });

        // This point will only be reached when there's an intermediate error when
        // confirming the payment. Otherwise, your customers will be redirected to
        // your return_url. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorise the payment,
        // then be redirected to the return_url.
        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message);
        } else {
            setMessage('An unexpected error occurred.');
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: 'tabs',
    };

    return (
        <form id='payment-form' onSubmit={handleSubmit}>
            <LinkAuthenticationElement
                id="link-authentication-element"
                onChange={(e) => {
                    if (e.target !== undefined) {
                        setEmail(e.target.value);
                    }
                }}
            />
            <PaymentElement id='payment-element' options={paymentElementOptions} />
            
            <button disabled={isLoading || !stripe || !elements} id='submit'>
                <span id='button-text'>
                    {isLoading ? <div className='spinner' id='spinner'></div> : 'Donate now'}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id='payment-message'>{message}</div>}
        </form>
    );
}