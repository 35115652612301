import React, { useState, useEffect } from 'react';
import Logo from './Logo';

const wedfDonateApiDomain = 'https://donate-api.worldethicaldata.org';
const wedfApiKey = "Q6trH3EV2M8e8hrb5fF2sQerVKofRTwMbKbByqFK";

export default function Quickbit({ amount, currency = 'eur' }) {
    const [orderDetails, setOrderDetails] = useState({});

    useEffect(() => {
        // Generate the Quickbit payment request
        fetch(wedfDonateApiDomain + '/create-qb-payment-intent', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + wedfApiKey,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ amount: amount, currency: currency }),
        })
        .then((res) => res.json())
        .then((data) => setOrderDetails(data));
    }, [amount, currency]);

    return (
        <div className='m-5 rounded border border-wedf-grey-900 max-w-xl quickbit-component mx-auto bg-white'>
            <div>
                <Logo />
            </div>

            <div className="p-5">
                {orderDetails && (
                    <iframe className="max-w-[100%]" style={{ width: '100%', height: '800px' }}
                        title="Quickbit" src={orderDetails.checkout_url} allow="clipboard-write; clipboard-read" />
                )}
            </div>
        </div>
    );
}