import Button from './Button';

export default function RecurringGroup({ setAmounts, typeDonation, setTypeDonation, activeButton, setActiveButton, setEnteredCustomAmount, setSelectedAmount }) {
    const options = [
        {value: "onetime", label: "One-time", active: true},
        {value: "monthly", label: "Monthly", active: false}
    ];

    const clickHandler = function(type) {
        let activeButtons = activeButton;
        for (let i = 0; i < activeButtons.length; i++) {
            activeButtons[i] = false;
        }
        setActiveButton(activeButtons);
        setEnteredCustomAmount(false);
        setSelectedAmount(false);

        setTypeDonation(type);
        if (type === "onetime") {
            // TODO get these amounts from the App.js so we have 1 source of truth
            setAmounts([1000, 2000, 5000, 10000, 50000, 100000]);
        } else {
            setAmounts([500, 1000, 2000, 5000, 10000, 20000]);
        }
    }

    return (
        <div className="grid sm:grid-cols-2 grid-cols-1">
            {options.map((option, idx) => {
                if (typeDonation === option.value) {
                    return <Button key={option.key} label={option.label} value={option.value} inverse smallText onClick={() => clickHandler(option.value)} />
                } else {
                    return <Button key={option.key} label={option.label} value={option.value} smallText onClick={() => clickHandler(option.value)} />
                }
            })}
        </div>
    );
}