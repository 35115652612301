import { useState, useEffect } from 'react';

import Header from './Header';
import DonateGroup from './DonateGroup';
import CustomAmount from './CustomAmount';
import Button from './Button';
import Quickbit from './Quickbit';
import Stripe from './Stripe';
import RecurringGroup from './RecurringGroup';

function App() {
  const [clickedStripe, setClickedStripe] = useState(false);
  const [clickedCrypto, setClickedCrypto] = useState(false);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState({currency: 'eur', symbol: '€'});
  const [activeButton, setActiveButton] = useState(new Array(6).fill(false));
  const [enteredCustomAmount, setEnteredCustomAmount] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(false);
  const [amounts, setAmounts] = useState([1000, 2000, 5000, 10000, 50000, 100000]);
  const [typeDonation, setTypeDonation] = useState('onetime');
  const [checkoutUrl, setCheckoutUrl] = useState('');

  const params = new URLSearchParams(window.location.search);
  const isSponsorShip = params.get('type') === 'sponsorship';

  const wedfDonateApiDomain = 'https://donate-api.worldethicaldata.org';
  const wedfApiKey = "Q6trH3EV2M8e8hrb5fF2sQerVKofRTwMbKbByqFK";
  
  const buttonLabels = {
    'donate': {
      'donate': 'Donate',
      'donate-crypto': 'Donate with crypto'
    },
    'sponsorship': {
      'donate': 'Pay',
      'donate-crypto': 'Pay with crypto'
    },
  };
  const labels = (!isSponsorShip) ? buttonLabels['donate'] : buttonLabels['sponsorship'];

  const priceIndices = new Map();
  // Test prices:
  /*priceIndices.set(500, 'price_1NqbbwKi9VQpONGCSCj75IJq');
  priceIndices.set(1000, 'price_1NqbcNKi9VQpONGC2wJ535sS');
  priceIndices.set(2000, 'price_1NqbcgKi9VQpONGCj9bR6nJC');
  priceIndices.set(5000, 'price_1Nqbd0Ki9VQpONGCeEIYfH5P');
  priceIndices.set(10000, 'price_1NqbdGKi9VQpONGCTq6lyehc');
  priceIndices.set(20000, 'price_1NqbdGKi9VQpONGCTq6lyehc');*/

  // Production prices:
  priceIndices.set(500, 'price_1NqbenKi9VQpONGC4OryJ9B4');
  priceIndices.set(1000, 'price_1NqbezKi9VQpONGCp5WHCFnp');
  priceIndices.set(2000, 'price_1NqbfAKi9VQpONGCHC4s1xEa');
  priceIndices.set(5000, 'price_1NqbfQKi9VQpONGCUiQ3cLYQ');
  priceIndices.set(10000, 'price_1NqbfbKi9VQpONGCCvrOR2p3');
  priceIndices.set(20000, 'price_1NqbgIKi9VQpONGC0MIoDBuy');
  
  useEffect(() => {
    setCurrency({currency: 'eur', symbol: '€'}); // TODO do this based on locale (EU, US, GB)
  }, []);

  return (
    (!clickedStripe && !clickedCrypto ?
      <>
        <div className="m-5 rounded border border-wedf-grey-900 max-w-xl mx-auto bg-white">
          <Header isSponsorship={isSponsorShip} />

          {!isSponsorShip &&
          <>
            <RecurringGroup
              setAmounts={setAmounts}
              typeDonation={typeDonation}
              setTypeDonation={setTypeDonation}
              activeButton={activeButton}
              setActiveButton={setActiveButton}
              setEnteredCustomAmount={setEnteredCustomAmount}
              setSelectedAmount={setSelectedAmount} />

            <DonateGroup 
              currencySymbol={currency.symbol}
              setAmount={setAmount}
              activeButton={activeButton}
              setActiveButton={setActiveButton}
              setEnteredCustomAmount={setEnteredCustomAmount}
              setSelectedAmount={setSelectedAmount}
              amounts={amounts} />
          </>
          } 
          
          {typeDonation === "onetime" &&
            <CustomAmount
            currencySymbol={currency.symbol}
            setAmount={setAmount}
            setActiveButton={setActiveButton}
            enteredCustomAmount={enteredCustomAmount}
            setEnteredCustomAmount={setEnteredCustomAmount}
            setSelectedAmount={setSelectedAmount}
            isSponsorship={isSponsorShip} />
          }
          
          <div className="text-center grid sm:grid-cols-2 grid-cols-1 mb-5">
            {(!selectedAmount) ? 
              <>
                  <Button label={labels['donate']} inverse smallText onClick={() => setClickedStripe(true)} disabled />
                  {typeDonation === "onetime" &&
                    <Button label={labels['donate-crypto']} inverse smallText onClick={() => setClickedCrypto(true)} disabled />
                  }
              </>
            : 
              <>
                {(typeDonation === "onetime") 
                  ? 
                  <Button label={labels['donate']} inverse smallText onClick={() => setClickedStripe(true)} />
                  : 
                  <Button label={labels['donate']} inverse smallText onClick={() => {
                    fetch(wedfDonateApiDomain + '/create-stripe-checkout-session', {
                      method: 'POST',
                      headers: {
                          'Authorization': 'Bearer ' + wedfApiKey,
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({ price: priceIndices.get(amount) }),
                    })
                    .then((res) => res.json())
                    .then((data) => {
                      setCheckoutUrl(data.checkout_url);
                      setClickedStripe(true);
                    });
                  }} />
                }
                  {typeDonation === "onetime" &&
                    <Button label={labels['donate-crypto']} inverse smallText onClick={() => setClickedCrypto(true)} />
                  }
              </>
            }
          </div>
        </div>
      </> : 
        (clickedStripe 
          ? <Stripe amount={amount} currency={currency.currency} typeDonation={typeDonation} checkoutUrl={checkoutUrl} />
          : <Quickbit amount={amount} currency={currency.currency} />
        )
      )
  );
}

export default App;
