export default function Button({ label, onClick = undefined, inverse = false, smallText = false, disabled = false }) {
    let colorClassList = "text-wedf-green-500 border-wedf-green-500";
    let hoverClassList = "hover:bg-wedf-green-500 hover:text-white"
    if (inverse) {
        colorClassList = "text-white bg-wedf-green-500 border-wedf-green-500";
        hoverClassList = "hover:bg-wedf-green-400 hover:border-wedf-green-400";
    }
    if (disabled) {
        colorClassList = "text-white bg-wedf-grey-900 border-wedf-grey-900";
        hoverClassList = "hover:bg-wedf-grey-900 hover:border-wedf-grey-900";
    }

    const textClassList = smallText ? "text-xl" : "text-2xl";

    return (
        <button className={`${colorClassList} p-5 mx-5 mt-5 rounded ${textClassList} font-semibold border-2 ${hoverClassList}`} onClick={onClick} disabled={disabled}>
            {label}
        </button>
    );
}