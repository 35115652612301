import Logo from './Logo';

export default function Header({ isSponsorship = false }) {
    return (
        <div>
            <Logo />

            {isSponsorship ? 
                <>
                    <h1 className="m-5 text-lg text-wedf-grey-200 font-semibold">Sponsor WEDF</h1>

                    <p className="m-5 text-wedf-grey-200">
                        Thank you for supporting WEDF. Please select or input the amount you would like to donate below.
                    </p>
                </>
            : 
                <>
                    <h1 className="m-5 text-lg text-wedf-grey-200 font-semibold">Support WEDF</h1>

                    <p className="m-5 mb-0 text-wedf-grey-200">
                        Thank you for supporting WEDF. Please select or input the amount you would like to donate below.
                    </p>
                </>
            }
        </div>
    );
}